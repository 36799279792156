import React from "react"

const SvgTwitter = props => (
  <svg preserveAspectRatio="xMidYMid" viewBox="0 0 256 208.046" {...props}>
    <path
      d="M256 24.628a105.04 105.04 0 01-30.166 8.27c10.845-6.5 19.172-16.793 23.093-29.057a105.183 105.183 0 01-33.351 12.745C205.995 6.379 192.346 0 177.239 0c-29.006 0-52.523 23.516-52.523 52.52 0 4.117.465 8.125 1.36 11.97-43.65-2.191-82.35-23.1-108.255-54.876-4.52 7.757-7.11 16.78-7.11 26.404 0 18.222 9.273 34.297 23.365 43.716a52.312 52.312 0 01-23.79-6.57c-.003.22-.003.44-.003.661 0 25.447 18.104 46.675 42.13 51.5a52.592 52.592 0 01-23.718.9c6.683 20.866 26.08 36.05 49.062 36.475-17.975 14.086-40.622 22.483-65.228 22.483-4.24 0-8.42-.249-12.529-.734 23.243 14.902 50.85 23.597 80.511 23.597 96.606 0 149.433-80.031 149.433-149.435 0-2.278-.05-4.543-.152-6.795A106.749 106.749 0 00256 24.628"
      fill="#f6f6f6"
    />
  </svg>
)

export default SvgTwitter

