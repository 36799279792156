import React from "react"

const SvgEmail = props => (
  <svg preserveAspectRatio="xMidYMid" viewBox="0 0 40 26.666" {...props}>
    <path
      d="M39.813 3.053a.302.302 0 00-.333.065L23.235 19.36a4.584 4.584 0 01-6.48 0L.517 3.118a.3.3 0 00-.334-.065.305.305 0 00-.183.28v20a3.332 3.332 0 003.333 3.333h33.334A3.332 3.332 0 0040 23.333v-20a.302.302 0 00-.187-.28zm-21.288 14.54a2.087 2.087 0 002.947 0L37.615 1.45a.83.83 0 00.202-.852C37.633.05 37.123 0 36.667 0H3.333c-.458 0-.971.05-1.151.598a.83.83 0 00.201.852z"
      fill="#f6f6f6"
    />
  </svg>
)

export default SvgEmail

